/*
 * @Desc:
 * @Author: JacksonZhou
 * @Date: 2021/11/28
 * @LastEditTime: 2022/01/04
 */
import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import "../css/tags.css";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  let posts = data.allMarkdownRemark.nodes;
  let tags: any[] = [];
  posts.forEach((post) => {
    const currentTags = post.frontmatter.tags.split(",");
    if (currentTags.length > 0) {
      currentTags.forEach((currentTag) => {
        const current = tags.find((tag) => tag.title === currentTag);
        if (!current) {
          tags.push({
            title: currentTag,
            count: 1,
          });
        } else {
          current.count = current.count + 1;
        }
      });
    }
  });

  // function color16() {
  //   let r = Math.floor(Math.random() * 256);
  //   let g = Math.floor(Math.random() * 256);
  //   let b = Math.floor(Math.random() * 256);
  //   let color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
  //   return color;
  // }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="tags-box">
        {tags.map((tag) => {
          return (
            <span
              key={tag.title}
              className="tags-item"
              style={{
                fontSize: 12 + tag.count * 4,
                opacity: (tag.count * 4) / 10,
              }}
            >
              {tag.title}
            </span>
          );
        })}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___createTime], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          createTime(formatString: "YYYY/MM/DD")
          updateTime(formatString: "YYYY/MM/DD")
          title
          tags
        }
      }
    }
  }
`;
